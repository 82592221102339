<template>
  <div>
    <p class="font-bold mt-3 text-lg" style="margin-left: 20px">My Profile</p>
    <div class="tabs-box m-5">
      <el-form ref="form" :rules="rules" :model="formData" label-width="160px">
        <el-form-item label="First Name：" prop="student_givenName_pinyin">
          <el-input v-model="formData.student_givenName_pinyin" placeholder="Please input" :disabled="!isEdit"></el-input>
        </el-form-item>
        <el-form-item label="Last Name：" prop="student_lastName_pinyin">
          <el-input v-model="formData.student_lastName_pinyin" placeholder="Please input" :disabled="!isEdit"></el-input>
        </el-form-item>
        <el-form-item label="Gender：" prop="student_gender">
          <el-select v-model="formData.student_gender" placeholder="Please select" :disabled="!isEdit">
            <el-option
                v-for="item in genderList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Graduation Year：" prop="student_graduation">
          <el-select v-model="formData.student_graduation" clearable placeholder="Please select" :disabled="!isEdit">
            <el-option
                v-for="item in yearOpt"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="School：">
          <el-input v-model="formData.school" placeholder="Please input" disabled></el-input>
        </el-form-item>
        <el-form-item label="Email：" prop="student_email">
          <el-input v-model="formData.student_email" placeholder="Please input" disabled></el-input>
        </el-form-item>
      </el-form>
      <p class="text-sm bg-white px-4 mt-3">
        Please confirm your name and school, the information will be used for the certificate.
      </p>
      <div style="width: 100%; margin: 30px 0 0 160px">
        <el-button type="warning" @click="editInfo">Edit</el-button>
        <el-button type="danger" @click="saveInfo">Save</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudentPurchasdPasses, getUserProfile, updateStudentInfo } from "../../api/eae";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      userProfile: {},
      isLoading: false,
      activeName: 'first',
      formData: {},
      rules: {
        student_givenName_pinyin: [
          {required: true, message: 'Please input the name', trigger: 'blur'},
        ],
        student_lastName_pinyin: [
          {required: true, message: 'Please input the name', trigger: 'blur'},
        ],
        student_email: [
          {required: true, message: 'Please input the email', trigger: 'blur'},
          {type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change']}
        ]
      },
      yearOpt: [],
      isEdit: false,
      isShowSaveBtn: false,
      genderList: [{
        label:'Male',
        value:'男',
      },{
        label:'Female',
        value:'女',
      }],
      passList: [],
      selIndex: null
    };
  },
  mounted() {
    const currentYear = new Date().getFullYear()
    this.yearOpt = []
    for(let i=0;i<6;i++){
      this.yearOpt.push(currentYear+i)
    }
    this.getProfile();
  },
  methods: {
    async getProfile() {
      this.isLoading = true;
      const res = await getUserProfile({
        student_id: getUserId(),
      });
      this.isLoading = false;
      this.userProfile = res.data.data;
      this.formData = res.data.data;
      this.formData.school = res.data.data.account.account_nameEN
      if (res.data.status === "success") {
      } else {
        this.$message.error("error");
      }
      getStudentPurchasdPasses(getUserId()).then((res) => {
        this.passList = res.data
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    editInfo(){
      this.isEdit = true
    },
    saveInfo(){
      this.isEdit = false
      console.log(this.formData,'---------')
      updateStudentInfo(
          getUserId(),
          this.formData.student_lastName_pinyin,
          this.formData.student_givenName_pinyin,
          this.formData.student_gender,
          this.formData.student_graduation,
      ).then((res)=>{
          console.log(123)
      })
    },
    passListSel(index){
      console.log(index, '12332')
      this.selIndex = index
    }
  },
};
</script>

<style lang="scss" scoped>
.tabs-box{
  .el-form{
    width: 560px;
  }
}
</style>